<script>
import ENUMS from '@shared/messages/enums.json';

export default {
  messages: {
    status: ENUMS.trainingStatus,
    statusClass: ENUMS.trainingStatusClass,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <span class="tag mr-3">
    <b-icon class="mr-1 is-size-9" icon="circle" :type="$options.messages.statusClass[this.status]" />
    {{ $options.messages.status[this.status] }}
  </span>
</template>
