<script>
import TrainingStatusTag from '@app/components/TrainingStatusTag.vue';
import Draggable from 'vuedraggable';
import APIBundles from '@app/services/API/Bundles';

export default {
  components: { TrainingStatusTag, Draggable },
  async created() {
    this.isFetching = true;
    await this.$store.dispatch('trainings/resetSearchParams');
    await this.$store.dispatch('trainings/fetch')
      .finally(() => (this.isFetching = false));
  },
  data() {
    return {
      movingTrainingUuids: [],
      isFetching: false,
      columns: [
        {
          field: 'order',
          label: 'Ordre',
          width: '40',
          numeric: true,
        },
        {
          field: 'name',
          label: 'Titre de la formation',
        },
        {
          field: 'status',
          label: 'Statut',
        },
      ],
    };
  },
  computed: {
    bundle() {
      return this.$store.state.bundles.current;
    },
    trainings() {
      return this.$store.state.trainings?.list?.data ?? [];
    },
    notIncludedTrainings() {
      return this.trainings.filter((training) => !this.isIncluded(training)).filter((training) => training.status !== 'DRAFT');
    },
  },
  methods: {
    isIncluded(training) {
      return !!this.bundle.items.find((item) => item.buyable.uuid === training.uuid);
    },
    addTraining(training) {
      this.movingTrainingUuids = [...this.movingTrainingUuids, training.uuid];
      APIBundles.addTraining(this.$store.state.bundles.current, training)
        .then(() => this.$store.dispatch('bundles/find', this.bundle.uuid))
        .then(() => this.$showMessage.success())
        .finally(() => {
          this.movingTrainingUuids = this.movingTrainingUuids.filter((uuid) => uuid !== training.uuid);
        });
    },
    removeTraining(training) {
      this.movingTrainingUuids = [...this.movingTrainingUuids, training.uuid];
      APIBundles.removeTraining(this.$store.state.bundles.current, training)
        .then(() => this.$store.dispatch('bundles/find', this.bundle.uuid))
        .then(() => this.$showMessage.success())
        .finally(() => {
          this.movingTrainingUuids = this.movingTrainingUuids.filter((uuid) => uuid !== training.uuid);
        });
    },
    updateReorder(items) {
      this.bundle.items = items;
      APIBundles.reorder(this.bundle, this.bundle.items)
        .then(() => this.$showMessage.success());
    },
  },
};
</script>

<template>
  <div>
    <section class="pb-7">
      <h2 class="is-size-4 has-text-weight-semibold py-3">
        Organisez les formations de votre pack
      </h2>

      <div class="box">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th></th>
              <!-- <th>Ordre</th> -->
              <th>Titre de la formation</th>
              <th>Statut</th>
              <th></th>
            </tr>
          </thead>

          <Draggable v-if="bundle.items.length > 0" tag="tbody" :value="bundle.items" @input="updateReorder"
            handle=".handle">
            <tr v-for="(item) in bundle.items" :key="item.uuid">
              <td class="handle valign-middle">
                <b-icon icon="bars" class="cursor-pointer" />
              </td>
              <!-- <td>
                {{ index + 1 }}
              </td> -->
              <td class="valign-middle">
                {{ item.buyable.name }}
              </td>
              <td class="valign-middle">
                <TrainingStatusTag :status="item.buyable.status" />
              </td>
              <td class="is-flex is-fullwidth is-justify-content-end" style="gap: 8px;">
                <router-link tag="b-button" :to="{ name: 'training', params: { uuid: item.buyable.uuid } }"
                  class="is-small">
                  <b-icon icon="eye" class="mr-1" />
                  Voir la formation
                </router-link>
                <b-button native-type="button" type="is-danger" size="is-small" @click="removeTraining(item.buyable)"
                  :disabled="movingTrainingUuids.includes(item.buyable.uuid)">
                  <b-icon v-if="!movingTrainingUuids.includes(item.buyable.uuid)" icon="trash" />
                  <span v-else class="loader is-white is-size-5" />
                </b-button>
              </td>
            </tr>
          </Draggable>
          <tbody v-else>
            <tr class="is-empty">
              <td colspan="5">
                <p class="p-5 has-text-centered">
                  Aucune formation
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section class="pb-7">
      <h2 class="is-size-4 has-text-weight-semibold py-3">
        Ajoutez de nouvelles formations
      </h2>

      <div class="box">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <!-- <th>Ordre</th> -->
              <th>Titre de la formation</th>
              <th>Statut</th>
              <th></th>
            </tr>
          </thead>

          <tbody v-if="notIncludedTrainings.length > 0">
            <tr v-for="training in notIncludedTrainings" :key="training.uuid">
              <td class="valign-middle">
                {{ training.name }}
              </td>
              <td class="valign-middle">
                <TrainingStatusTag :status="training.status" />
              </td>
              <td class="is-flex is-fullwidth is-justify-content-end" style="gap: 8px;">
                <router-link tag="b-button" :to="{ name: 'training', params: { uuid: training.uuid } }"
                  class="is-small">
                  <b-icon icon="eye" class="mr-1" />
                  Voir la formation
                </router-link>
                <b-button native-type="button" type="is-primary" size="is-small" @click="addTraining(training)"
                  :disabled="movingTrainingUuids.includes(training.uuid)">
                  <b-icon v-if="!movingTrainingUuids.includes(training.uuid)" icon="plus" />
                  <span v-else class="loader is-white is-size-5" />
                </b-button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="is-empty">
              <td colspan="3">
                <p class="p-5 has-text-centered">
                  Aucune formation
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<!-- <script>
import Draggable from 'vuedraggable';
import trainingsMixin, { trainingItemUtils } from '@app/mixins/trainings';
import TrainingItemSingle from '@app/components/TrainingItemSingle.vue';
import TrainingCreateDropdown from '@app/components/TrainingCreateDropdown.vue';
import TrainingEditEnforceProgression from '@app/components/TrainingEditEnforceProgression.vue';

export default {
  mixins: [trainingsMixin, trainingItemUtils],
  components: {
    TrainingEditEnforceProgression,
    TrainingCreateDropdown,
    TrainingItemSingle,
    Draggable,
  },
  head: {
    title: 'Contenu',
  },
  computed: {
    hasPercentPlan() {
      return this.$store.getters['auth/hasPercentPlan'];
    },
    isNotRecurringPlan() {
      return this.$store.getters['auth/isNotRecurringPlan'];
    },
    currentTrainingItems: {
      get() {
        return this.$store.state.trainings.current.items;
      },
      set(trainingItems) {
        this.$store.dispatch('trainings/reorderItems', {
          uuid: this.currentTraining.uuid,
          trainingItems,
        })
          .then(() => this.$showMessage.success());
      },
    },
  },
  watch: {
    'currentTrainingItems.length': {
      handler(val, oldVal) {
        if (val > oldVal) {
          const lastItem = this.currentTrainingItems[val - 1];
          this.$nextTick(() => this.$scrollTo(`#training-item-${lastItem.id}`));
        }
      },
    },
  },
  methods: {
    scrollToTrainingItemIfpossible() {
      const { hash } = this.$route;

      if (hash) {
        this.$scrollTo(hash, { duration: 0 });
        this.$router.replace({ hash: '' });
      }
    },
    goPro() {
      this.$showMessage.goPro(() => {
        this.$router.push({ name: 'plan', query: { onglet: 'vous' } });
      });
    },
  },
  mounted() {
    this.scrollToTrainingItemIfpossible();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'training') {
      this.$nextTick(() => this.scrollToTrainingItemIfpossible());
    }

    next();
  },
};
</script>

<template>
  <section class="page">
    <header v-if="currentTrainingItems.length" class="is-flex is-justify-content-space-between">
      <TrainingEditEnforceProgression />
      <TrainingCreateDropdown />
    </header>

    <div v-else class="content has-text-centered my-20 w-640 mx-auto">
      <h2>Ajoutez vos contenus pédagogiques</h2>
      <p class="help">
        Créez des leçons ou des quiz contenant du texte, des liens, des images,
        des vidéos et plus encore !
      </p>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Chapitres (facultatifs)</h3>
          <p class="help">
            Pour <strong>structurer le parcours de formation</strong>,
            vous pouvez créer des Chapitres.
            Chaque chapitre peut contenir plusieurs leçons et quiz.
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button type="is-primary" @click="addSection()">
            Ajouter un chapitre
          </b-button>
        </nav>
      </div>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Leçons</h3>
          <p class="help">
            Créez vos leçons avec tous les contenus multimédia que vous souhaitez :
            <strong>textes</strong>, <strong>images</strong>, <strong>vidéos</strong>,
            <strong>citations</strong>, <strong>annexes</strong>, <strong>liens externes</strong>
            , etc...
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button type="is-primary" @click="addGeneric()">
            Créer une leçon
          </b-button>
        </nav>
      </div>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Quiz multimédia</h3>
          <p class="help">
            Besoin d'évaluer vos apprenants ?
            Rajoutez des quiz pour les aider à valider leurs acquis.
            Créez <strong>des quiz ludiques grâce aux images, vidéos et même audios.</strong>
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button type="is-primary" @click="addQuiz()">
            Élaborer un quiz
          </b-button>
        </nav>
      </div>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Devoirs</h3>
          <p class="help">
            <strong>Évaluez, commentez et notez</strong> les travaux individuels
            de vos apprenants en créant un devoir.
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button v-if="isNotRecurringPlan" type="is-primary" @click="goPro">
            Créer un devoir
          </b-button>
          <b-button v-else type="is-primary" @click="addAssignment()">
            Créer un devoir
          </b-button>
        </nav>
      </div>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Tâches</h3>
          <p class="help">
            Confiez <strong>des tâches & missions</strong> à vos apprenants
            pour les faire passer à l'action (cases à cocher).
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button v-if="hasPercentPlan" type="is-primary" @click="goPro">
            Créer des tâches à faire
          </b-button>
          <b-button v-else type="is-primary" @click="addTasks()">
            Créer des tâches à faire
          </b-button>
        </nav>
      </div>
      <div class="box media has-text-left">
        <div class="media-content">
          <h3>Scorm</h3>
          <p class="help">
            Vous avez déjà vos <strong>contenus aux formats SCORM 1.2 ou SCORM 2004</strong> ?
            Importez-les tout simplement dans vos leçons Teachizy.
          </p>
        </div>
        <nav class="media-right ml-5 is-align-self-center">
          <b-button type="is-primary" @click="addScorm">
            Importer un contenu
          </b-button>
        </nav>
      </div>
    </div>

    <div class="mt-5">
      <draggable v-model="currentTrainingItems" handle=".handle">
        <TrainingItemSingle v-for="item in currentTrainingItems" :key="item.id" :training="currentTraining"
          :item="item" />
      </draggable>
    </div>

    <nav v-if="currentTrainingItems.length" class="mt-10 buttons is-centered">
      <TrainingCreateDropdown position="is-top-left" />
    </nav>

    <router-view />
  </section>
</template>

<style lang="scss" scoped>
.page {
  ::v-deep .sortable-ghost {
    border: 2px solid $primary;
  }
}
</style> -->
